import * as React from 'react'
import * as Yup from 'yup';
import { Box, Button, Divider, Typography } from '@mui/material'

import GoogleSheetImport from 'components/GoogleSheetImport';
import { useLoading, useSnackbar } from 'contexts';
import useStore from './store';
import shallow from 'zustand/shallow';
import { createQuestions, getPathwayById, getPathwayQuesitons } from 'services/pathwayService';

const sx = {
  saveBtn: {
    color: '#fff',
    textTransform: 'capitalize',
    width: 'fit-content'
  }
}

const QuestionSchema = Yup.object().shape({
  questions: Yup.array(Yup.object().shape({
    domain: Yup.string().required('Domain is required'),
    tag: Yup.string().required('Tag is required'),
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
    allottedTime: Yup.number().min(30).max(300).required('Allotted Time is not valid'),
    type: Yup.string().oneOf(['technical', 'situational'], 'Type must be either "technical" or "situational"').required('Questions Type is required'),
    isMasterQuestion: Yup.boolean().required('isMasterQuestion is required'),
  }))
});

const Questions = () => {
  const snackbar = useSnackbar();
  const loading = useLoading();

  const pathway = useStore(state=> state.pathway, shallow);
  const questions = useStore(state=> state.questions, shallow);
  const setQuestions = useStore(state=> state.setQuestions, shallow);
  const setPathway = useStore(state=> state.setPathway, shallow);

  const [existingQuestions, setExistingQuestions] = React.useState([]);

  const handleSubmit = async () => {
    try {
      loading.show();
      await createQuestions({pathwayId: pathway._id, questions});
      setExistingQuestions([...existingQuestions, ...questions]);
      setQuestions([]);

      const result = await getPathwayById(pathway._id);
      setPathway(result);

      snackbar.showSnackbar("Questions saved successfully !!");
    } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
    }finally{
      loading.hide();
    }
    };

    const handleImport = async (rows) => {
      try{
        loading.show();
        const valid = await QuestionSchema.validate({ questions: rows }, { abortEarly: false});
        if (!valid) {
          snackbar.error(
            "Seems like you have some invalid data!! Please check the student list"
          );
          return;
        }
        else{
          setQuestions(rows);
        }
      }catch(err){
        console.error(err.inner[0] || err.message, "error");
        snackbar.showSnackbar(err.inner[0]?.errors[0] || err.message, "error");
      } finally{
        loading.hide();
      }
    };


  React.useEffect(() => {
    (async () => {
      try {
          const interviewQuestions = await getPathwayQuesitons({pathway: pathway?._id});
          setExistingQuestions(interviewQuestions);
      } catch (error) {
          console.log(error);
      }
    })();
  }, []);

  return (
    <Box>
      <GoogleSheetImport 
        handleImport={handleImport}
        sampleSheetUrl={'https://docs.google.com/spreadsheets/d/1wM1xVMNy2AujLVVN9Qift1HnIhyzfgv74wLkIJVyHW4/edit?usp=sharing'}
      />
      <Divider/>
      <Box mt='20px' display='flex' flexDirection='column' alignItems='flex-end' p='0px 20px'>
        <Typography>
          Total Questions Imported: {questions.length}
        </Typography>
        <Button variant='contained' style={sx.saveBtn} disabled={!questions.length} 
          onClick={handleSubmit}
        >
          Save Questions
        </Button>
      </Box>
      <Box>
      <Typography>
          Total Saved Questions: {existingQuestions?.length}
        </Typography>
      </Box>
    </Box>
  )
}

export default Questions