import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  fieldsetRoot: {
    padding: 0,
    background: "#e4e3e8",
    borderRadius: 4,
  },
  questionError: {
    fontSize: 8,
    color: "red",
    marginLeft: 8,
    marginTop: -4,
  }
});

function FieldSet({ readOnly, label, children, error }) {
  const classes = useStyles();

  return (
    <div>
      <fieldset
        className={classes.fieldsetRoot}
        style={{ borderColor: Boolean(error) ? "red" : '#C4C4C4' }}
        disabled={readOnly}
      >
        <legend style={{ fontSize: 12 }}>{label}</legend>
        {children}
      </fieldset>
      <span className={classes.questionError}>
        {!!error && error}
      </span>
    </div>
  );
}

export default FieldSet;