import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import CustomButton from 'components/CustomButton';
import { useSnackbar, useLoading } from "contexts";
import { copyToClipboard } from "utils";
import { TextField } from '@mui/material';
import { exportPathwayAnalytics } from 'services/pathwayService';

function ExportPathwayAnalyticsDialog({
    open,
    onClose = () => { },
    onSuccess = () => { },
    pathway
}) {
    const snackbar = useSnackbar();
    const loading = useLoading();
    const [email, setEmail] = React.useState([]);
    const [responseUrl, setResponseUrl] = React.useState(false);

    React.useEffect(() => {
        setEmail([]);
        setResponseUrl(false);
    }, [open]);

    const handleExportAnalytics = async () => {
        try {
            loading.show();

            let { url } = await exportPathwayAnalytics(pathway._id, {email});

            setResponseUrl(url);
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        }
        finally {
            loading.hide();
        }
    }

    const handleCopyUrlClick = () => {
        copyToClipboard(responseUrl);
        snackbar.showSnackbar("URL copied to clipboard", "success", true);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle >
                Export {pathway?.name}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 500, maxWidth: 900, overflow: "hidden" }}>
                {!responseUrl ? (
                    <>
                      <TextField
                        id="outlined"
                        label="Email"
                        defaultValue={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                      <br />
                      <br />
                      <CustomButton
                          variant="contained"
                          disabled={email.length <= 0}
                          onClick={handleExportAnalytics}
                      >
                          Export
                      </CustomButton>
                    </>
                ) : (
                    <Box
                        border="1px solid lightgrey"
                        my="1em"
                        p="1em"
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1} overflow="hidden">
                            <Typography
                                variant="subtitle1"
                                color="blue"
                                component="a"
                                sx={{ wordWrap: "break-word" }}
                                href={responseUrl}
                                target={"_blank"}
                            >
                                {responseUrl}
                            </Typography>
                        </Box>
                        <Box ml={1}>
                            <IconButton variant="outlined">
                                <ContentCopyIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={handleCopyUrlClick}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                )}
                <CustomButton
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
}

export default ExportPathwayAnalyticsDialog;