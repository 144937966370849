import { useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import FieldSet from "./FieldSet";
import useField from "./useField";

const useStyles = makeStyles({
    editor: {
        "&[contenteditable=true]:empty:before": {
            content: 'attr(placeholder)',
            pointerEvents: 'none',
            display: 'block',
            color: 'grey',
            backgroundColor: 'transparent'
        }
    }
});

export default function ContentEditable({
    index, readOnly, question, defaultValue,
}) {
    const classes = useStyles();

    const editorRef = useRef();

    const { handleChange, error, config } = useField({ index, name: 'question' });

    const handleInput = (e) => {
        e.target.value = e.target.innerHTML;
        e.target.name = 'question';
        handleChange(e);
    }

    useEffect(() => {
        editorRef.current.innerHTML = defaultValue || "";
    }, []);

    return (
        <FieldSet readOnly={readOnly} label={`Q${index + 1}*`} error={error}>
            <div
                contentEditable={!readOnly}
                ref={editorRef}
                name={'question'}
                placeholder={config.placeholder}
                className={classes.editor}
                onInput={handleInput}
                style={{
                    width: "100%",
                    height: 60,
                    overflowY: 'scroll',
                    padding: 8,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    outline: 'none'
                }}
            >
            </div>
        </FieldSet>
    );
}