import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import FormTextField, { FormCopyTextField } from "components/FormTextField";
import ContentHeader from "components/ContentHeader";
import FileUploadInput from "components/FileUploadInput";
import CustomButton from "components/CustomButton";
import { useSnackbar } from "contexts";
import { getUserById, saveClient } from "services";
import { formatDate } from "utils";
import { FormCheckbox } from "components/FormCheckbox";
import SEPScaleEditor from "./SEPScaleEditor";

const clientValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    redirectUrl: Yup.string()
        .url()
        .required('Required'),
    userLimit: Yup.number()
        .min(1 | -99, "Should be greater than zero or -99 for Unlimited")
        .notOneOf([0], 'Cannot be zero')
        .required('Required'),
    logoUrl: Yup.object().shape({
        url: Yup.string().required()
    }).required(),
    sepWebHookUrl: Yup.string()
        .url()
        .optional(),
    sepRedirectName: Yup.string()
        .optional(),
    sepDynamicDescription: Yup.string()
        .optional(),
    emailSEPReport: Yup.boolean()
        .optional(),
});

const InitialValues = {
    name: '',
    email: '',
    redirectUrl: '',
    userLimit: -99,
    logoUrl: { url: '', name: '' },
    apiSecret: 'Auto generated',
    createdAt: 'Auto generated',
    newUserSignupUrl: 'Auto generated',
    sepWebHookUrl: '',
    sepRedirectName: '',
    sepDynamicDescription: 'Welcome to your Spoken <br /> English Proficiency test',
    emailSEPReport: false,
    roles: [],
};

const useStyles = makeStyles({
    fields: { margin: 20 },
    fieldContainer: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copyIcon: {
        marginLeft: '8px',
        cursor: 'pointer'
    },
    buttonContainer: {
        margin: "30px 10px"
    }
});

const getFields = (id) => [
    {
        required: true,
        name: "name",
        label: "Name",
    },
    {
        required: true,
        name: "email",
        label: "Email",
        disabled: id !== "create",
    },
    {
        required: true,
        name: "redirectUrl",
        label: "Redirect Url",
    },
    {
        name: "createdAt",
        label: "Registration Date",
        disabled: true,
    },
    {
        name: "sepWebHookUrl",
        label: "SEP Webhook URL",
    },
    {
        name: "sepRedirectName",
        label: "SEP redirect  name",
    },
    {
        name: "sepDynamicDescription",
        label: "SEP Dynamic Description",
        type: "code",
        defaultValue: "Welcome to your Spoken <br /> English Proficiency test"
    },
];

export default function SEPClientForm() {
    const classes = useStyles();
    const { id } = useParams();

    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(InitialValues);

    const handleSubmit = async (values) => {
        try {
            const client = { role: "SEP_CLIENT" };
            client["name"] = values.name;
            client["email"] = values.email;
            const metadata = {};
            metadata["redirectUrl"] = values.redirectUrl;
            metadata["logoUrl"] = values.logoUrl?.url;
            metadata["sepWebHookUrl"] = values.sepWebHookUrl;
            metadata["sepRedirectName"] = values.sepRedirectName;
            metadata["sepDynamicDescription"] = values.sepDynamicDescription;
            metadata["emailSEPReport"] = values.emailSEPReport;
            metadata["userLimit"] = (values.userLimit < 0) ?
                -99 :
                values.userLimit;

            let clientId = null;
            if (id && id !== 'create') {
                metadata["apiSecret"] = values.apiSecret;
                metadata["newUserSignupUrl"] = values.newUserSignupUrl;
                clientId = id;
            }
            client["metadata"] = metadata;

            const savedClient = await saveClient(clientId, client);

            snackbar.showSnackbar("Client saved successfully !!");

            navigate(`/clients/sep/${savedClient._id}`);
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (id && id !== 'create') {
                    const client = await getUserById(id, { roles: true });
                    const logoName = client.metadata?.logoName;
                    const logoUrl = client.metadata?.logoUrl;

                    const newInitialValues = {
                        name: client.name,
                        email: client.email,
                        ...client?.metadata,
                        createdAt: formatDate(client.createdAt),
                        logoUrl: {
                            url: client.metadata?.logoUrl || "",
                            name: logoName || logoUrl.slice((logoUrl.lastIndexOf("/") + 1)),
                        },
                        roles: client.roles,
                    };

                    setInitialValues(newInitialValues);
                }
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                setLoading(false)
            }
        })();
    }, [id]);

    const goBack = () => navigate("/clients/sep");

    return (<>
        <ContentHeader title="SEP Client Form" />
        <br />
        {!loading && (
            <Formik
                validationSchema={clientValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, errors, setFieldValue }) => (
                    <Form>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '32% 32% 32%',
                                gridColumnGap: "2%"
                            }}
                        >
                            {id && getFields(id).map((field, index) => (
                                <FormTextField key={index} {...field} />
                            ))}

                            <FormCopyTextField
                                disabled
                                name="apiSecret"
                                label="Secret key"
                            />

                            <FormCheckbox
                                label="Send Report Email ?"
                                name="emailSEPReport"
                            />

                            <FileUploadInput
                                name="logoUrl"
                                label="upload client logo"
                                file={{
                                    name: values.logoUrl.name,
                                    url: values.logoUrl.url
                                }}
                                onUploadCancelled={() => setFieldValue('logoUrl', {})}
                                onUpload={(file) => setFieldValue('logoUrl', file)}
                                errors={{ file: errors['logoUrl'] || false }}
                                toPublic={true}
                                uploadLocation='logos'
                            />
                        </div>
                        <Box className={classes.buttonContainer}>
                            <CustomButton
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </CustomButton>
                            <CustomButton
                                variant="outlined"
                                onClick={goBack}
                            >
                                Cancel
                            </CustomButton>
                        </Box>
                    </Form>)}
            </Formik>
        )}
        <br />
        {(id !== "create") && <SEPScaleEditor clientId={id} />}
    </>);
}