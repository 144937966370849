import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonthSelect from 'components/MonthSelect';
import BatchSelect from 'components/BatchSelect';
import { getMonthlyAnalyticsById, getUsersCountForClient } from 'services';

const monthsList = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
]
const valueToNameMapMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}

const useStyles = makeStyles({
    table: {
        "& .MuiTableCell-root": {
            border: '1px solid black'
        }
    }
});


function MonthlyAnalyticsDialog({ open = false, client, onClose }) {

    const [selectedMonths, setSelectedMonths] = React.useState([]);
    const [selectedBatches, setSelectedBatches] = React.useState([]);
    const [dataList, setDataList] = React.useState({});
    const [dateType, setDataType] = React.useState("relative");
    const [createdAtStart, setCreatedAtStart] = React.useState("");
    const [createdAtEnd, setCreatedAtEnd] = React.useState("");
    const [userCount, setUserCount] = React.useState("");
    
    const { clientId, clientName } = React.useMemo(() => {
        return { clientId: client?.id ?? "", clientName: client?.name?.trim() ?? "Client" };
    }, [client]);

    useEffect(() => {
        (async () => {
            if (clientId) {
                if (createdAtStart) {
                    const data = await getMonthlyAnalyticsById(
                        clientId,
                        { createdAtStart: createdAtStart, createdAtEnd: createdAtEnd,
                        batches:selectedBatches }
                    );
                    setDataList(data);
                } else if (selectedMonths.length > 0) {
                    const data = await getMonthlyAnalyticsById(clientId, { months: selectedMonths,batches:selectedBatches })
                    setDataList(data);
                }
            }
        })()

    }, [clientId, selectedMonths,selectedBatches, createdAtStart, createdAtEnd])

    useEffect(() => {
        (async () => {
            if (clientId) {
                const data = await getUsersCountForClient(clientId);
                setUserCount(data);
            }
        })()
    }, [clientId, dateType])


    const handleMonthChange = (value) => {
        value.sort();
        setSelectedMonths(value);
    }

    const handleBatchChange=(value)=>{
        setSelectedBatches(value);
    }

    const handleDateTypeChange = (event, value) => {
        setDataList({});
        setSelectedMonths([]);
        setCreatedAtEnd("");
        setCreatedAtStart("");
        setDataType(value);
    }

    return (
        <Dialog fullScreen={false}
            open={open}
            maxWidth={"80vw"}
            aria-labelledby="responsive-dialog-title"
            onClose={() => {
                setDataList({});
                setSelectedMonths([]);
                setCreatedAtEnd("");
                setCreatedAtStart("");
                onClose();
            }}
        >
            <DialogTitle id="responsive-dialog-title">
                <Box display={'flex'} alignItems={"center"} 
                    justifyContent={'space-between'} paddingX='2rem'>
                    {clientName} Analytics
                    <Box>
                        <Typography variant="body1" color="initial">
                            User Count: {userCount}
                        </Typography>
                        <BatchSelect onChange={handleBatchChange} client={client?.id}/>
                    </Box>
                </Box>

            </DialogTitle>
            <DialogContent >
                <Box display={'flex'} justifyContent={'space-between'}>
                    <ToggleButtonGroup
                        color="primary" value={dateType} exclusive
                        onChange={handleDateTypeChange} aria-label="Device"
                        style={{ height: '30px', widht: "100%", marginInline: "auto", marginTop: "0.5 rem" }}
                    >
                        <ToggleButton value="absolute" style={{ textTransform: 'none' }}>Absolute</ToggleButton>
                        <ToggleButton value="relative" style={{ textTransform: 'none' }}>Month Wise</ToggleButton>
                    </ToggleButtonGroup>
                    {dateType === 'relative' ?
                        (<MonthSelect months={monthsList} onChange={handleMonthChange} />) :
                        (<Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"}
                            justifyContent={"space-around"}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="CreatedAt Begin"
                                    onChange={(value) => {
                                        const dateString = (new Date(value.$d)).toISOString();
                                        setCreatedAtStart(dateString)
                                        if (createdAtEnd == "") {
                                            setCreatedAtEnd(dateString)
                                        }
                                    }}
                                    sx={{ maxWidth: "48%" }}
                                />
                                <DatePicker
                                    label="CreatedAt End"
                                    sx={{ maxWidth: "48%" }}
                                    onChange={(value) => {
                                        const dateString = (new Date(value.$d)).toISOString();
                                        setCreatedAtEnd(dateString)
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>)
                    }
                </Box>
                <MonthlyAnalyticsTable daysList={selectedMonths} data={dataList} dateType={dateType} />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color={'error'} onClick={onClose}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}

function MonthlyAnalyticsTable({ daysList = [], data = {}, dateType }) {

    const classes = useStyles();

    return (<TableContainer component={Paper}>
        <Table aria-label="simple table" className={classes.table} sx={{ minWidth: "50vw" }}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} align="left"><Typography variant='h6'>
                        Parameters
                    </Typography></TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">Entries</TableCell>) :
                            daysList.map((el) =>
                            (<TableCell align="left"><Typography variant='h6'>
                                {valueToNameMapMonth[el]}</Typography></TableCell>))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell rowSpan={2} align="left">Attempts</TableCell>
                    <TableCell align="left">Finished</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.attempts?.finished ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (
                                    <TableCell align="center">
                                        {data?.attempts?.finished?.[day] ?? 0}
                                    </TableCell>
                                )
                            })
                    }
                </TableRow>
                <TableRow>
                    <TableCell align="left">Unfinished</TableCell>

                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.attempts?.unfinished ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.attempts?.unfinished?.[day] ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell rowSpan={2} align="left">Active Users</TableCell>
                    <TableCell align="left">Unique Attempts</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.activeUsers?.uniqueAttempts ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.activeUsers?.uniqueAttempts?.[day] ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell align="left">Last Active</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.activeUsers?.lastActive ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.activeUsers?.lastActive?.[day] ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2} >Onboarded User</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.onboardedUsers ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.onboardedUsers?.[day] ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2} >Batches Created</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.batchesCreated ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.batchesCreated?.[day] ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell rowSpan={2} align="left">Assessments Created</TableCell>
                    <TableCell align="left">Assignment</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.assignmentCreated?.numberOfAssignment ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.assignmentCreated?.[day]?.numberOfAssignment ?? 0}</TableCell>)
                            })}
                </TableRow>
                <TableRow>
                    <TableCell align="left">Practice</TableCell>
                    {
                        dateType === 'absolute' ?
                            (<TableCell align="center">{data?.assignmentCreated?.numberOfPractice ?? 0}</TableCell>) :
                            daysList.map((day) => {
                                return (<TableCell align="center">{data?.assignmentCreated?.[day]?.numberOfPractice ?? 0}</TableCell>)
                            })}
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>);
}

export default MonthlyAnalyticsDialog