import Client from "./_client";
import Session from "../utils/Session";

export const getRoles = async (roleFilters = {}) => {
    let url = "/role?";

    if (!roleFilters.user) roleFilters.user = Session.userId;

    Object.keys(roleFilters).forEach((key) => {
        if (roleFilters[key]) url += `${key}=${roleFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get roles. Please try again.');
    }

    return result.data;
}

export const getRoleById = async (roleId) => {
    const result = await new Client({
        path: `/role/${roleId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get role. Please try again.');
    }

    return result.data;
}

export const saveRole = async (roleId, name, permissions, applications) => {
    let result;

    if (roleId) {
        result = await new Client({
            path: `/role/${roleId}`,
            payload: { name, permissions, applications },
        }).put();
    } else {
        result = await new Client({
            path: `/role`,
            payload: { name, permissions, applications },
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save role. Please try again."
        );
    } else {
        return result.data;
    }
}

export const deleteRoleById = async (roleId) => {

    let url = `/role/${roleId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete role. Please try again."
        );

    return result.data;
}


