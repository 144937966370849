import React from 'react';
import shallow from "zustand/shallow";
import { FieldConfigs } from "./constants";
import useEditorStore from './editorStore';

const useField = ({ index, name }) => {
  const FieldConfig = React.useMemo(() => FieldConfigs[name], [name]);
  // const validate = useEditorStore(state => state.validate, shallow);
  // const value = useEditorStore(state => state.questions[index][name], shallow);
  // const error = useEditorStore(state => state.questionsError[index]?.[name], shallow);

  const updateQuestion = useEditorStore(state => state.updateQuestion, shallow);

  // const [error, setError] = React.useState(false);

  // const validateField = React.useCallback(() => FieldConfig.schema.validate(value)
  //   .then(() => setError(false))
  //   .catch(e => setError(e.message)), [value, FieldConfig]);

  // React.useEffect(validateField, [validateField, validate]);

  return {
    handleChange: (e) => updateQuestion(index, e),
    config: FieldConfig,
  };
};

export default useField;